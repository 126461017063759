.mission-vision {
  background-color: white;
}

.mission-vision-container {
  background-color: white;
  display: flex;
  flex-direction: row; /* Default desktop view */
  justify-content: space-around;
  align-items: center;
  margin: 50px 0;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.mission-box,
.vision-box {
  flex: 1;
  background-color: transparent;
  padding: 20px;
  margin: 0 20px;
  text-align: center;
}

.mission-box img,
.vision-box img {
  width: 80%;
  height: 300px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.mission-box h2,
.vision-box h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #333;
}

.mission-box p,
.vision-box p {
  font-size: 1rem;
  color: #666;
  text-align: left;
  padding-left: 65px;
  padding-right: 35px;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .mission-vision-container {
    flex-direction: column; /* Stack boxes vertically */
    margin: 20px 0; /* Reduce margin for mobile */
  }

  .mission-box,
  .vision-box {
    margin: 10px 0; /* Reduce margin between boxes */
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .mission-box img,
  .vision-box img {
    width: 70%; /* Full width for better display on mobile */
    height: auto; /* Adjust height automatically */
  }

  .mission-box h2,
  .vision-box h2 {
    font-size: 1.5rem; /* Smaller font size for headings */
  }

  .mission-box p,
  .vision-box p {
    font-size: 0.9rem; /* Smaller font size for paragraphs */
    padding-left: 15px; /* Reduce padding */
    padding-right: 15px; /* Reduce padding */
    text-align: center; /* Center-align text for better readability */
  }
}

@media (max-width: 500px) {
  .mission-box h2,
  .vision-box h2 {
    font-size: 1.3rem; /* Further reduce heading size */
  }

  .mission-box p,
  .vision-box p {
    font-size: 0.8rem; /* Smaller text for smaller screens */
  }
}
