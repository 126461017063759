/* SlideText Container */
.slide-text-wrapper {
  display: flex;
  flex-direction: column;
}

/* Heading Style */
.slide-text-heading {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  color: #fbf7f7;
  font-weight: bold;
  padding-top: 20px;
}

/* Styling for each slide-item */
.slide-item {
  display: flex;
  align-items: center;
  margin: 20px 0; /* Space between each item */
  opacity: 0;
  margin-left: 150px;
  transform: translateX(100%);
  transition: transform 0.7s ease-out, opacity 0.7s ease-out;
}

.slide-in {
  opacity: 1;
  transform: translateX(0);
}

/* Styling for the image */
.slide-image {
  width: 250px;
  height: auto;
  margin-left: 200px;
  margin-right: 150px; /* Reduced space between image and text */
  border-radius: 8px;
}

/* Styling for the text container */
.slide-text-container {
  flex: 1;
  background-color: transparent;
  padding: 15px; /* Reduced padding inside the text box */
  border-radius: 12px;
  margin-right: 100px;
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 0.7s ease-out, opacity 0.7s ease-out;
}

.slide-text-container p {
  color: white;
  font-size: 120%;
}

.slide-text-container h3 {
  font-size: 180%;
  color: white;
}

.slide-in-text {
  opacity: 1;
  transform: translateX(0);
}

/* Styling for second slide-item */
.second-slide-item {
  display: flex;
  align-items: center;
  margin: 20px 0; /* Space between each item */
  opacity: 0;
  margin-left: -25px;
  transform: translateX(-100%); /* Start from the left */
  transition: transform 0.7s ease-out, opacity 0.7s ease-out;
}

.second-slide-image {
  width: 250px;
  height: auto;
  margin-right: 150px;
  margin-left: 50px;
  border-radius: 8px;
}

.second-slide-in {
  opacity: 1;
  transform: translateX(0);
}

.second-slide-text {
  margin-left: 190px;
}

.second-slide-text p {
  color: white;
  font-size: 120%;
}

.second-slide-text h3 {
  font-size: 180%;
  color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
  /* Stack items vertically in mobile */
  .slide-item,
  .second-slide-item {
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
  }

  /* Adjust image size for mobile */
  .slide-image,
  .second-slide-image {
    width: 30%;
    height: auto;
    margin: 15px 0;
    border-radius: 8px;
    margin-bottom: -30px;
    margin-top: -10px;
  }

  /* Order text above image on mobile */
  .slide-text-container,
  .second-slide-text {
    order: -1;
    text-align: center;
    margin: 0;
    padding: 15px;
    margin-left: 30px;
    opacity: 1;
    transform: translateX(0);
  }

  /* Adjust font sizes for mobile */
  .slide-text-heading {
    font-size: 20px;
    margin-bottom: -15px;
  }

  .slide-text-container h3,
  .second-slide-text h3 {
    font-size: 18px;
    padding-right: 30px;
  }

  .slide-text-container p,
  .second-slide-text p {
    font-size: 13px;
    padding-right: 30px;
  }

  /* Padding for the mobile section */
  #slide-text-section {
    padding: 20px 10px;
  }
}
