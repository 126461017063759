.contact {
    background-color: white;
}

.banner_images_2 img {
    width: 100%;
    height: 500px;
}

/* Page Content Styling */
.page-content {
    padding: 20px;
    max-width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

/* Contact Information Section */
.contact-info-container-horizontal {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
}

.contact-info-item {
    flex: 1;
    min-width: 250px;
    margin: 10px;
}

.contact-info-box h3 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 15px;
}

/* Form and Image Section */
.contact-form-and-image {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
}

.contact-form {
    flex: 1;
    max-width: 600px;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #0056b3;
}

.contact-image {
    flex: 1;
    max-width: 500px;
    margin-left: 20px;
}

.contact-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Follow Us and Map Styling */
.follow-us-map {
    margin-top: 50px;
    text-align: center;
}

.follow-us-map h3 {
    font-size: 1.6em;
    margin-bottom: 20px;
}

.social-links a {
    margin: 10px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

/* .social-links a:hover {
    background-color: #0056b3;
} */

/* Map Styling */
.follow-us-map iframe {
    margin-top: 20px;
    width: 100%;
    height: 300px;
    border: none;
}

.page-header {
    margin-left: -240px;
}

