/* General Styles */
html, body {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  background-color: #001133;
}

/* Company Section Styles */
.company_section1 {
  background-color: #0f1e68ef;
  padding: 60px 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company_ul1 {
  padding-left: 40px;
  color: #eff1f4;
  font-size: 25px;
  margin-right: 150px;
}

.about-button {
  background-color: #a50303;
  color: #eff1f4;
  font-size: 18px;
  padding: 12px 25px;
  margin-right: 70px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
  white-space: nowrap;
}

.about-button:hover {
  background-color: #0056b3;
  color: #fff;
}


.products-section {
  padding: 40px 20px;
  background-color: #f5f5f5;
  text-align: center;
  border-radius: 12px;
  margin: 40px 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.products-container {
  max-width: 800px;
  margin: 0 auto;
}

.products-title {
  font-size: 32px;
  color: #333;
  margin-top: -25px;
}

.products-description {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 30px;
}

.products-button {
  display: inline-block;
  padding: 12px 20px;
  background-color: #001133;
  color: white;
  font-size: 16px;
  margin-bottom: -5px;
  text-decoration: none;
  border-radius: 6px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.products-button:hover {
  background-color: #0044cc;
  transform: scale(1.05);
}








/* Responsive Design */
@media (max-width: 768px) {


  .products-title {
    font-size: 19px;
  }

  .products-description {
      font-size: 13px;
  }

  .products-button {
      font-size: 14px;
      padding: 10px 16px;
  }




  .company_section1 {
    flex-direction: column;
    text-align: center;
    padding: 30px 10px;
  }

  .company_ul1 {
    font-size: 13px;
    margin-right: 30px;
    margin-left: -10px;
    margin-top: -15px;
    text-align: center;
    margin-bottom: 10px; /* Adds space between text and button */
  }

  .about-button {
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-bottom: -15px;
    
  }

  

}





