.prod {
  background-color: white;
}

/* Production Page Styles */
.production-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f9f9f9;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  }
  
  .production-title {
    text-align: center;
    font-size: 36px;
    color: #333;
    margin-bottom: 30px;
  }
  
  .production-intro, .production-commitment {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    text-align: center;
    margin-bottom: 40px;
  }
  
  /* Product Box Styles */
  .product-box {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    padding: 30px;
    margin-bottom: 30px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .product-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .product-title {
    font-size: 28px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .product-overview, .product-expertise {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
  }
  
  .product-applications {
    margin-top: 15px;
    font-size: 16px;
    color: #444;
  }
  
  .product-applications strong {
    display: block;
    margin-bottom: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .production-container {
      padding: 20px;
    }
  
    .production-title {
      font-size: 28px;
    }
  
    .product-title {
      font-size: 24px;
    }
  
    .product-box {
      padding: 20px;
    }
  }
  

  






/* .image-container {
  max-width: 800px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
} */

.production-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* .production-image:hover {
  transform: scale(1.05);
} */

/* Responsive Design */
/* @media (max-width: 768px) {
  .image-section {
    padding: 40px 10px;
  }

  .image-container {
    max-width: 100%;
  }
} */
