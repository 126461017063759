/* Header styling */
.header {
  background-color: #001133;
  /* background-color: #00330e; */
  padding: 10px 20px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-title-container {
  display: flex;
  align-items: center;
  margin-right: -140px;
} 

/* Desktop view: Logo container styles */
.logo-container {
  width: 150px;
  height: auto;
  margin-right: 15px;
  margin-top: -10px;
  padding-top: 0;  /* Resets padding for desktop */
  padding-right: 0; /* Resets padding for desktop */
}

.logo {
  margin-top: 10px;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.header-title {
  font-size: 22px;
  color: #f4f0f0;
  font-weight: bold;
  text-align: center;
  margin-left: 260px;
}




.search-results {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 350px;
  top: 50px;
  left: 14%;
  transform: translateX(-50%);
  z-index: 10;

}

.search-result-item {
  display: block;
  padding: 10px;
  color: black;
  text-decoration: none;
  border-bottom: 1px solid #eee;
}

.search-result-item:hover {
  background-color: #f0f0f0;
}






/* Mobile-specific styles */
@media screen and (max-width: 764px) {
  .logo-container {
    width: 100px;
    height: auto;
    padding-top: -20px;
    padding-right: 85px;
    margin-top: -10px;
    padding-left: -20px;
  }

  .header-title {
    font-size: 18px;
    text-align: center;
    margin-top: 30px;
    /* margin-right: 5px; */
    margin-left: -10px;
    padding-right: 30px;
    padding-left: 10px;
    
  }

  .nav_links {
    display: none;
    
  }

  /* .menu_icon, .mobile_search_icon {
    display: block;
    color: white;
    cursor: pointer;
    font-size: 25px;
  } */

  /* .sidebar {
    background-color: #001133; 
    width: 140px; 
    height: 70vh; 
    position: fixed; 
    margin-top: 110px;
    padding: 20px;
    color: #f4f0f0; 
    overflow-y: auto; 
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
  } */





    
  .navbar_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }


  .icons_container .menu_icon,
  .icons_container .mobile_search_icon {
    color: white;
    cursor: pointer;
    font-size: 25px;
    margin-left: 10px;
  }

  .sidebar {
    display: none;
    background-color: #001133;
    /* background-color: #576f9d; */
    width: 130px;
    height: 100vh;
    /* padding-top: 30px; */
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    padding: 20px;
    color: #f4f0f0;
  }

  .sidebar.show {
    display: block;
    transform: translateX(0);
    
  }

  .sidebar_link {
    display: block;
    color: #f4f0f0;
    margin-bottom: 15px;
    text-decoration: none;
    font-size: 18px;
  }

  .logoimage{
    width: 26vw;
    height: 9vh;
    padding-bottom: 20px;
    margin-top: -10px;
  }

   
  .input-wrapper {
    background-color: white;
    width: 60%;
    border-radius: 15px;
    height: 1.8rem;
    padding: 0 15px;
    /* box-shadow: 0px 0px 8px #ddd; */
    display: flex;
    align-items: center;
    margin-right: 15px;
    
  }

  input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: 1.25rem;
    width: 100%;
    margin-left: 5px;
    font-size: 13px;
  }

  input:focus {
    outline: none;
  }

  #search-icon {
    color: royalblue;
  }

  
  .search-results {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 51%; /* Adjusted for better fit on small screens */
    max-width: 300px; /* Ensures it doesn't exceed 300px */
    top: 50px;
    left: 67%;
    transform: translateX(-50%);
    z-index: 10;
    font-size: 13px;
}

.search-result-item {
    display: block;
    padding: 10px;
    color: black;
    text-decoration: none;
    /* border-bottom: 1px solid #eee; */
}





}

/* Desktop-specific styles */
@media screen and (min-width: 991px) {
  .header {
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
  }

  .logo-container {
    width: 150px;
    height: auto;
    margin-right: 15px;
    margin-top: 20px;
  }

  .logo {
    width: 150px; 
    height: 150px; 
    object-fit: contain;
  }

  .header-title {
    font-size: 22px;
    text-align: right;
  }

  .search_input {
    display: flex;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .menu_icon, .mobile_search_icon {
    display: none !important;
  }

  .nav_links {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }


    
  .input-wrapper {
    background-color: white;
    width: 25%;
    border-radius: 15px;
    height: 2rem;
    padding: 0 15px;
    /* box-shadow: 0px 0px 8px #ddd; */
    display: flex;
    align-items: center;
    margin-left: 15px;
  }

  input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: 1.1rem;
    width: 100%;
    margin-left: 5px;
  }

  input:focus {
    outline: none;
  }

  #search-icon {
    color: royalblue;
  }

}

