footer {
  background-color: #123055;
  color: white;
  text-align: center;
  margin-top: auto;
  padding: 20px;
}

/* Footer section wrapper */
#footer_section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
  color: white;
}

/* Footer sections styling */
.footer-section {
  flex: 1;
  margin: 10px;
  min-width: 200px; /* Ensure minimum width to avoid too small sections */
}

/* Footer heading styling */
.footer-section h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

/* Footer links list styling */
.footer-section ul {
  list-style-type: none;
  padding: 0;
}

/* List items styling */
.footer-section ul li {
  margin-bottom: 8px;
}

/* Footer links */
.footer-section a {
  color: white;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

/* Social icons in the footer */
.social-icons a {
  color: white;
  font-size: 24px;
  margin-right: 15px;
}

.indiamart-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

/* Indiamart logo size */
.indiamart-logo {
  width: 32px;
  height: auto;
  margin-right: 15px;
}

.indiamart-text {
  font-size: 15px;
}

/* Copyright section styling */
.footer-copyright {
  text-align: center;
  padding-bottom: 30px;
  background-color: #123055;
  color: #f7f1f1;
  font-size: 14px;
}

/* WhatsApp link styling */
.whatsapp-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  margin-left: 100px;
}

.whatsapp-logo {
  width: 32px;
  height: auto;
  margin-right: 15px;
}

.whatsapp-text {
  font-size: 15px;
}

/* Mobile View */
@media (max-width: 768px) {
  /* Footer layout changes for smaller screens */
  #footer_section {
    flex-direction: column;
    align-items: flex-start; /* Align sections to the left */
    padding-left: 20px; /* Add padding for better alignment */
  }
  
  .footer-section {
    margin: 15px 0;
    text-align: left;
  }
  
  .footer-section h2 {
    text-align: left; /* Align heading to the left */
    padding-left: 10px; /* Optional: add padding for consistency */
  }

  .footer-section ul {
    padding-left: 20px; /* Adjust padding for better spacing */
  }

  /* .footer-section ul li {
    margin-bottom: 12px;
  } */

  /* Footer icons spacing */
  .social-icons a {
    font-size: 13px;
    margin: 0 10px;
  }

  /* WhatsApp link for mobile */
  .whatsapp-link {
    margin-top: 10px;
    margin-left: -3px;
    
  }

  /* Adjust WhatsApp icon size for mobile */
  .whatsapp-logo {
    width: 28px;
  }

  .footer-font {
    font-size: 13px;
  }

  .whatsapp-text {
    font-size: 13px;
  }
}

