/* Background settings */
.randd {
  background-color: white;
}

/* Styling the content box */
.rnd-content {
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); /* Deeper shadow for depth */
  margin: 20px auto; /* Added vertical spacing */
  max-width: 800px;
}

/* Headings and paragraph styles */
.rnd-content h2 {
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #003366; /* Dark blue for emphasis */
  margin-left: 10%; /* Added left margin */
  margin-right: 10%; /* Added right margin */
  text-align: center;
}

.rnd-content h3 {
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 20px; /* Added bottom margin for better spacing */
  font-weight: bold;
  color: #00509e; /* Slightly lighter blue for headings */
  margin-left: 8%; /* Added left margin */
  margin-right: 8%; /* Added right margin */
}

/* Paragraphs and lists */
.rnd-content p,
.rnd-content ul {
  font-size: 18px;
  line-height: 1.6;
  color: #333; /* Dark text for readability */
  margin-left: 5%;
  margin-right: 5%;
}

/* List styles */
.rnd-content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 15px; /* Added spacing above lists */
}

/* Animating elements on scroll using AOS */
[data-aos="fade-up"] {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

[data-aos="fade-up"].aos-animate {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .rnd-content {
    padding: 20px;
    margin: 10px auto; /* Adjusted margin for smaller screens */
  }

  .rnd-content h2 {
    font-size: 28px;
    margin-left: 5%; /* Reduced margins for smaller screens */
    margin-right: 5%;
  }

  .rnd-content h3 {
    font-size: 22px;
    margin-left: 5%; /* Reduced margins for smaller screens */
    margin-right: 5%;
  }

  .rnd-content p,
  .rnd-content ul {
    font-size: 16px;
    margin-left: 5%; /* Consistent margins for all text */
    margin-right: 5%;
  }
}
