/* General slider styling */
.hero-slider {
  position: relative;
  overflow: hidden;
}

.slide-inner {
  height: 70vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -27px;
}

.home_banner_txt {
  text-align: center;
  color: white;
}

.slide-title, .slide-text, .slide-btns {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.slide-title.animate-in, .slide-text.animate-in, .slide-btns.animate-in {
  opacity: 1;
  transform: translateY(0);
}

.slide-title.animate-out, .slide-text.animate-out, .slide-btns.animate-out {
  opacity: 0;
  transform: translateY(50px);
}

.comn_btn {
  background-color: #f9f9f9;
  color: #000;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.comn_btn:hover {
  background-color: hsl(0, 0%, 0%);
  color: #f9f9f9;
}

/* Style for the slide heading */
.slide-heading h1 {
  font-size: 46px;
  font-weight: bold;
  margin-bottom: 100px;
  color: #fcfcfc;
}

/* Style for the slide title */
.slide-title h2 {
  font-size: 28px;
  margin-bottom: 15px;
  color: #fff;
}

.slide-text {
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  margin-bottom: 20px;
  padding: 0;
}

/* Mobile view adjustments */
@media screen and (max-width: 767px) {
  .slide-inner {
    height: 30vh; /* Reduce height for mobile */
    /* background-size: contain;  */
    padding: 10px;
    margin-bottom: -27px;
  }

  .slide-heading h1 {
    font-size: 18px; /* Smaller font size for mobile */
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .slide-title h2 {
    font-size: 13px; /* Smaller title font for mobile */
    margin-bottom: 5px;
  }

  .slide-text {
    font-size: 10px; /* Adjust text font size */
    margin-bottom: 5px;
  }

  .comn_btn {
    padding: 6px 12px;
    font-size: 10px; /* Smaller button font */
  }
}
