
.branches-section-slideshow {
    padding-top: 10px;
    padding-bottom: 20px;
    background: #f7f9fc;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  .branches-title-slideshow {
    font-size: 30px;
    color: #001133;
    font-weight: bold;
    margin-bottom: 20px;
    letter-spacing: 1px;
    position: relative;
  }
  
  .branches-marquee {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;
    height: 50px;
  }
  
  .branches-track {
    display: inline-flex;
    animation: marquee 10s linear infinite;
    gap: 50px;
  }
  
  .branch-item {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    background: #ffffff;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: inline-block;
    white-space: nowrap;
  }
  
  /* Animation for smooth scrolling effect */
  @keyframes marquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
  }
  
  /* Add a gradient effect for fade-out edges */
  .branches-section-slideshow::before,
  .branches-section-slideshow::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    z-index: 2;
    background: linear-gradient(to right, #f7f9fc, transparent);
  }
  
  .branches-section-slideshow::before {
    left: 0;
  }
  
  .branches-section-slideshow::after {
    right: 0;
    transform: scaleX(-1);
  }

  

@media (max-width: 768px) {
  .branches-title-slideshow {
    font-size: 18px; /* Smaller title for mobile */
    margin-bottom: 15px;
    letter-spacing: 0.5px;
    padding-bottom: -10px;

  }

  .branches-marquee {
    height: 40px; /* Reduced height for smaller screens */
  }

  .branches-track {
    animation: marquee 8s linear infinite; /* Faster animation for smaller screens */
    gap: 20px; /* Smaller gap between items */
  }

  .branch-item {
    font-size: 10px; /* Smaller font size */
    padding: 5px 10px; /* Compact padding */
    border-radius: 15px; /* Slightly smaller radius */
  }

  .branches-section-slideshow::before,
  .branches-section-slideshow::after {
    width: 30px; /* Narrower gradient edges */
  }

}