.popup-container {
  background-color: rgba(247, 248, 250, 0.9);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 40px 0;
  animation: popUp 1s ease-out forwards;
}

.popup-box {
  width: 22%;
  margin: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.popup-box:hover {
  transform: scale(1.05);
}

.popup-box img {
  width: 100%;
  height: auto;
}

.popup-box h3 {
  margin-top: 10px;
}

.popup-container h1 {
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
}

.popup-container h3 {
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .popup-container {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 20px 10px;
    padding: 10px;
  }

  .popup-box {
      width: 30%;
      margin: 10px 0;
      text-align: center;
  }

  .popup-box img {
      width: 60%;
      margin: 0 auto;
      padding-top: 10px;
  }

  .popup-container h1 {
      font-size: 15px;
      padding: 5px;
  }

  .popup-container h3 {
      font-size: 12px;
      padding: 5px;
  }

  .popup-box h3 {
      font-size: 13px;
  }
}
