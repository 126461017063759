.about_page {
  background-color: white;
}

/* Image Section Styling */
.image-section {
  position: relative;
  width: 100vw; /* Full width of the viewport */
  height: 50vh; /* Adjust the height of the image section */
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent overflow */
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.about-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container without being cut */
}

/* About Section Styling */
.about-section {
  padding: 60px 20px;
  color: #060505;
  text-align: center;
  background-color: #f7f7fa; /* Dark background color for contrast */
}

/* .about-content, .journey-section {
  background-color: rgba(255, 255, 255, 0.9); 
  margin: 20px auto;
  max-width: 800px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); 
} */

.about-content h1, .journey-section h2 {
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: left;
  padding-left: 90px;
  
}

.about-content p.lead-text {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 300;
  color: #090808;
  text-align: left;
  padding-left: 90px;
  padding-right: 550px;
}

.journey-section p {
  font-size: 18px;
  line-height: 1.6;
  color: #030202;
  text-align: left;
  padding-left: 90px;
  padding-right: 550px;
}


.image2 {
  position: relative;
  width: 50vw; 
  height: 50vh;
  margin-top: -1000px;
  margin-left: 600px;
  /* padding-top: -100px; */
  padding-left: 300px;
  padding-right: 10px;
  overflow: hidden; 
}



/* Responsive Design */
@media (max-width: 768px) {
  .about-content,
  .journey-section {
    padding: 10px 15px; /* Narrow padding for mobile */
  }

  .about-content h1,
  .journey-section h2 {
    font-size: 22px;
    padding-left: 15px;
  }

  .about-content p.lead-text,
  .journey-section p {
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .image-section {
    height: 30vh; /* Smaller image height on mobile */
  }

  /* Image positioning for .image2 */
  .image2 {
    width: 50vw; /* Full width on mobile */
    height: auto; /* Adjust height to maintain aspect ratio */
    margin-top: 20px;
    margin-left: 0;
    padding: 0 10px; /* Center-align image */
  }
}

/* Fade-up Animation */
[data-aos="fade-up"] {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

[data-aos="fade-up"].aos-animate {
  opacity: 1;
  transform: translateY(0);
}






/* Core Values Section Styling */
.core-values-section {
  padding: 60px 20px;
  background-color: #f0f8ff; /* Light background for a clean look */
  text-align: center;
}

.core-values-section h2 {
  font-size: 36px;
  font-weight: bold;
  color: #002244; /* Dark blue color for heading */
  margin-bottom: 20px;
  text-transform: uppercase;
}

.core-values-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 800px; /* Limits width for better readability */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.core-values-list li {
  font-size: 18px;
  color: #333;
  padding: 10px 15px;
  background-color: #ffffff; /* White background for contrast */
  border: 1px solid #ddd; /* Light border */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover Effects */
.core-values-list li:hover {
  transform: translateY(-5px); /* Lift up on hover */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* Slightly deeper shadow */
}

/* Responsive Design */
@media (max-width: 768px) {
  .core-values-section {
    padding: 40px 10px; /* Reduced padding for smaller screens */
  }

  .core-values-section h2 {
    font-size: 20px; /* Smaller font for mobile */
  }

  .core-values-list li {
    font-size: 12px; /* Adjust text size for readability */
    padding: 8px 10px; /* Adjust padding for compact design */
  }
}








/* Why Choose Us Section Styling */
.why-choose-us-section {
  padding: 40px 20px;
  text-align: center;
  background-color: #f0f4f8; /* Light background for differentiation */
}

.why-choose-us-section h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #002e5d;
}

.why-choose-us-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.why-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1 1 250px;
  max-width: 300px;
}

.why-box h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #003366;
}

.why-box p {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}


@media (max-width: 768px) {
  .why-choose-us-content {
    flex-direction: column;
    gap: 15px;
  }

  .why-choose-us-section h2 {
    font-size: 20px;
  }

  .why-box {
    background-color: #fff;
    border-radius: 8px;
    padding: 50px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex: 1 1 250px;
    width: 250px;
    margin-left: 35px;
    
  }
  
  .why-box h3 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #003366;
  }

  .why-box p {
    font-size: 13px;
    line-height: 1.5;
    color: #333;
    
  }
}


