/* General Styles */
.common-section {
    padding: 60px 0;
    background-color: #f9f9f9;
  }
  
  .border-bottom {
    border-bottom: 1px solid #ddd;
  }


  
  .container-xl {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .fw500 {
    font-weight: 500;
  }
  
  .fw600 {
    font-weight: 600;
  }
  
  .fw800 {
    font-weight: 1000;
  }
  
  /* Contact Address Section */
  .contactFormAddress {
    padding: 30px;
    background-color: #fff;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* border-radius: 8px; */
  }
  
  .contactFormInner i {
    color: #ff5722;
  }
  
  .text-orange-link {
    color: #ff5722;
    text-decoration: none;
  }
  
  .text-orange-link:hover {
    text-decoration: underline;
  }
  
  /* Divider Line */
  .verticleBorderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .verticleBorder {
    width: 5px;
    height: 100%; /* Make sure it spans the height of the entire section */
    min-height: 500px; /* Set a minimum height for visibility */
    background-color: #ff5722;
  }
  
  /* Ensure spacing between the two sections */
  .contactFormAddress {
    margin-right: 100px;
  }
  
  .col-sm-6 {
    margin-left: 100px;
  }
  
  /* Contact Form Section */
  .fz18 {
    font-size: 18px;
  }
  
  .fz24 {
    font-size: 24px;
  }
  
  .form-control {
    padding: 12px;
    width: 600px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .form-control:focus {
    outline: none;
    border-color: #ff5722;
    box-shadow: 0 0 5px rgba(255, 87, 34, 0.5);
  }
  
  .common-btn {
    background-color: #ff5722;
    color: white;
    width: 130px;
    height: 40px;
    font-weight: 600;
    border: none;
    font-size: 20px;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }
  
  .common-btn:hover {
    background-color: #e64a19;
  }
  
  
  
.col-sm-6 p {
    padding-bottom: 40px;
    padding-top: 20px;
}

.no-underline-black {
    text-decoration: none;
    color: black;
    
  }


.img_class {
    width: 40px;
    height: 40px;
  }


.social_media {
    background-color: white;
}




.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.popup-content h4 {
  margin-bottom: 10px;
}

.popup-content button {
  padding: 10px 20px;
  background-color: #f06c00;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #d95a00;
}




@media (max-width: 768px) {
  .container-xl {
    padding: 0 10px; /* Add padding inside the container for better spacing */
    padding-bottom: 50px;
  }

  .form-control {
    width: 100%; /* Ensure form controls take full width */
    font-size: 14px;
    padding: 12px; /* Increase padding for mobile devices */
  }

  .contactFormAddress {
    margin-right: 0; /* Remove any right margin */
    margin-bottom: 20px; /* Add margin at the bottom */
  }

  .common-btn {
    width: 100%; /* Full-width button on mobile */
    height: 45px;
    font-size: 18px;
  }

  .col-sm-6 {
    margin-left: 0; /* Remove left margin */
    padding: 0 15px; /* Add padding for smaller screens */
  }

  /* Make the vertical line horizontal on mobile or hide it completely */
  .verticleBorder {
    display: none; /* Hide the vertical line on mobile */
  }
  
  /* Optional: If you want to make it horizontal instead */
  .verticleBorderContainer {
    display: flex;
    flex-direction: column; /* Stack vertically */
  }

  .verticleBorder {
    width: 100%; /* Make it full width on mobile */
    height: 5px; /* Make it a horizontal line */
    background-color: #ff5722; /* Maintain the color */
  }
  

}


