/* Updated Products Section Styling */
.new-products-section {
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.new-products-title {
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: bold;
  color: #333;
}

.new-products-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px; /* Add space between cards */
}

.new-product-card {
  margin: 20px;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 220px;
  transition: transform 0.3s ease;
}

.new-product-card:hover {
  transform: scale(1.05);
}

.new-product-image-container {
  width: 120px;
  height: 120px;
  overflow: hidden;
  margin: 0 auto 10px;
  border-radius: 50%;
  border: 2px solid #ddd;
}

.new-product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.new-product-name {
  font-size: 1.4rem;
  margin: 10px 0 5px;
  color: #333;
}

.new-product-link {
  font-size: 1rem;
  color: #0066cc;
  text-decoration: none;
  font-weight: bold;
}

.new-product-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .new-products-container {
    justify-content: space-evenly;
  }

  .new-product-card {
    width: 200px; /* Adjust width for tablet */
  }
}

@media (max-width: 768px) {
  .new-products-section {
    padding: 40px 10px;
  }

  .new-products-title {
    font-size: 28px;
  }

  .new-products-container {
    flex-direction: column; /* Stack cards vertically on mobile */
    align-items: center;
  }

  .new-product-card {
    width: 80%; /* Allow card to take more width on mobile */
    margin: 10px 0; /* Reduce margin for tighter spacing */
  }

  .new-product-image-container {
    width: 100px; /* Slightly reduce image size */
    height: 100px;
  }

  .new-product-name {
    font-size: 1.2rem;
  }

  .new-product-link {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .new-products-title {
    font-size: 24px;
  }

  .new-product-card {
    width: 90%; /* Further adjust width for smaller mobile screens */
  }
}
