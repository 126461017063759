.testing {
  background-color: white;
}

/* Container styling */
.testing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

/* Title and content styles */
.testing-title {
  text-align: center;
  font-size: 36px;
  color: #333;
  margin: 20px 10px 30px; /* Adjusted margins */
}

.testing-intro, .testing-commitment {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  text-align: center;
  margin: 0 15px 40px; /* Added left-right margin */
}

/* Testing Box Styles */
.testing-box {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 30px;
  margin: 20px 15px; /* Added margin for better spacing */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testing-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Subtitles and overview text */
.testing-subtitle {
  font-size: 28px;
  color: #333;
  margin-bottom: 15px;
}

.testing-overview {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin-left: 10px; /* Added margin for alignment */
}

/* Testing Areas */
.testing-areas {
  margin-top: 15px;
  font-size: 16px;
  color: #444;
  padding-left: 20px; /* Indentation for list items */
}

.testing-areas strong {
  display: block;
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .testing-container {
    padding: 20px;
  }

  .testing-title {
    font-size: 28px;
  }

  .testing-subtitle {
    font-size: 24px;
  }

  .testing-box {
    padding: 20px;
  }

  .testing-intro, .testing-commitment {
    font-size: 16px;
    margin: 0 10px 30px; /* Reduced margin for smaller screens */
  }
}

/* Image Section */
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.production-image {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}
