/* Team Members Section Styling */
.team-members-section {
    padding: 60px 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .team-members-title {
    font-size: 36px;
    margin-bottom: 40px;
    font-weight: bold;
    color: #333;
  }
  
  .team-members-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .team-member-card {
    margin: 20px;
    text-align: center;
    width: 200px; /* Control the width of each team member card */
  }
  
  .team-member-image-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 10px;
  }
  
  .team-member-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .team-member-name {
    font-size: 1.5rem;
    margin: 10px 0 5px;
  }
  
  .team-member-role {
    font-size: 1.2rem;
    color: #666;
  }
  
  /* Ensure responsiveness */
  @media (max-width: 700px) {
    .team-member-card {
      width: 150px;
    }
    
    .team-members-title {
      font-size: 28px;
    }
  
    .team-member-name {
      font-size: 1.2rem;
    }

    .team-member-role {
      font-size: 1rem;
      color: #666;
    }
  }
  